import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";

function IndexPage(props) {
  
  return (
    <Layout {...props}>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <h1 className="text-4xl">Welkom bij Voetsalon Akkrum</h1>
      <h2 className="text-3xl py-8">Momenteel is er geen ruimte meer voor nieuwe klanten</h2>
      <p>
      Ik ben Jitske van der vegt, sinds 2009 gediplomeerd pedicure.
      </p>
      <p>
      Thuis op begaande grond heb ik een ruimte ingericht als pedicure salon, dus ook toegankelijk voor mensen die slecht ter been zijn.
      </p>
      <p>
      voeten worden vaak vergeten. we gaan regelmatig naar de kapper of schoonheidsspecialiste. maar waarom niet naar de pedicure?
      ze zitten verstopt in de schoenen. maar we lopen en staan er elke dag op.
      dus onze voeten mogen we niet vergeten.
      </p>
      <p>
      Ik behandel likdoorns, eelt, kloven, ingegroeide nagels, schimmelnagels.
      </p>
      <p>
      Bel vrijblijvend voor informatie tel: 0566785163
      wordt er niet opgenomen? spreek uw telefoonnummer in en ik bel u terug.<br/>
      De openingstijden:<br/>
      Ma 8.30 tot 12.00<br/>
      Di 8.30 tot 20.00<br/>
      Do11.00 tot 17.00<br/>
      Vrij 8.30 tot 17.00<br/>
      </p>

{/* 
      <section className="text-center">
        <img
          alt="Cat and human sitting on a couch"
          className="block w-1/2 mx-auto mb-8"
          src={catAndHumanIllustration}
        />

        <h2 className="inline-block p-3 mb-4 text-2xl font-bold bg-yellow-400">
          Hey there! Welcome to your first Gatsby site.
        </h2>

        <p className="leading-loose">
          This is a barebones starter for Gatsby styled using{` `}
          <a
            className="font-bold text-gray-900 no-underline"
            href="https://tailwindcss.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tailwind CSS
          </a>
          , a utility-first CSS framework.
        </p>
      </section> */}
    </Layout>
  );
}

export default IndexPage;
